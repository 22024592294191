import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import "./style.css";
function MyApp() {
  const [value, onChange] = useState(new Date());
  const [min, setMin] = useState(new Date());

  useEffect(() => {
    const ini = moment().subtract(60, "day");
    setMin(ini);
  }, []);

  const changeValue = (event) => {
    const month = moment(event).month() + 1;
    const year = moment(event).year();

    let weeks =
      moment(event).weeks() - moment(event).startOf("month").weeks() + 1;
    const week = (weeks + 52) % 52;
    const docName = `document_${year}_${month}_${week}`;
    onChange(event);

    OpenFile(docName);
  };

  const OpenFile = (docName) => {
    const url = `https://mirurldeldocument.com/${docName}.pdf`;
    window.open(url, "_blank");
  };

  return (
    <div>
      <Calendar onChange={changeValue} value={value} minDate={new Date(min)} />
    </div>
  );
}

export default MyApp;
